<template>
    <div class="term-extraction-index-page">
        <full-loading ref="fullLoading"></full-loading>
        <div class="page-tools-content">
            <div class="left">
                <el-button type="grey" size="small" round icon="el-icon-plus" v-permission="`tools:extractionRouter:new`" @click="dialog.show = true; dialog.type = 'double'">双语文档</el-button>
                <el-button plain size="small" round icon="el-icon-plus" v-permission="`tools:extractionRouter:new`" @click="dialog.show = true; dialog.type = 'single'">单语文档</el-button>
                <el-button size="small" plain round icon="el-icon-refresh-left" style="margin-left:10px;" :loading="refreshLoading" @click="initPage('reload')">刷新</el-button>
            </div>
            <div class="right">
                <div style="width:220px;">
                    <el-input placeholder="输入名称" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <div style="width:124px;margin-left:10px;">
                    <el-select v-model="searchForm.sort" size="small" placeholder="排序方式">
                        <el-option key="1" value="1" label="按时间排序"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <card-list v-if="list.length>0"
            :type="`termExtraction`"
            :list="list"
            @action="executeAction"></card-list>
        <div class="none-list-block" v-else>
            <div class="none-files-content">
                <svg-icon name="ib-empty"></svg-icon>
                <div class="info" v-if="queryType === 'search'">没有找到任务～</div>
                <div class="info" v-else>一键智能提取术语，并提供初始翻译</div>
                <div>
                    <el-button type="primary" size="small" round icon="el-icon-plus" v-permission="`tools:extractionRouter:new`" @click="dialog.show = true; dialog.type = 'double'" v-if="queryType !== 'search'">双语文档</el-button>
                    <el-button plain size="small" round icon="el-icon-plus" v-permission="`tools:extractionRouter:new`" @click="dialog.show = true; dialog.type = 'single'">单语文档</el-button>
                </div>
            </div>
        </div>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="`${dialog.type === 'double' ? '双语' : '单语'}术语提取`" :visible.sync="dialog.show" destroy-on-close width="712px" top="20px">
            <div class="dialog-content" v-if="userInfo && userInfo.featureConfig">
                <template v-if="dialog.type === 'double'">
                    <type-radio :list="typeRadioOptions" :active="typeRadioSelected" @change="typeRadioChange" style="justify-content: center;margin-bottom:15px;"></type-radio>
                </template>
                <div class="tips">
                    支持{{ dialog.type === 'double' ? (typeRadioSelected === 'double' ? '原文译文段段对照的文档，' : '原文译文上下对照的文档，') : ''}}{{dialog.type === 'double' && typeRadioSelected === 'single' ? dialog.singleSupportFiles: dialog.supportFiles}}格式，单个文件最大{{userInfo.featureConfig.singleFileSize}}MB
                    <el-tooltip placement="top">
                        <div slot="content" style="max-width: 320px;line-height: 1.5;">按照原文字符消耗流量。如上传双文档，按照原文文档计；如上传段段对照单文档，按照单文档字符计。</div>
                        <span class="margin-l-10 color-link cursor-pointer">流量扣费规则</span>
                    </el-tooltip>
                </div>
                <div class="upload-files-content">
                    <div>
                        <upload-files 
                            ref="fileUpload"
                            :title="dialog.type === 'double' ? (typeRadioSelected === 'double' ? '上传原文' : '上传文档') :'上传文档'" 
                            :size="userInfo.featureConfig.singleFileSize"
                            :accept="dialog.type === 'double' && typeRadioSelected === 'single' ? dialog.singleAccept : dialog.accept"
                            :multiple="true"
                            :limit="10"
                            :params="{}"></upload-files>
                    </div>
                    <div class="margin-l-40" v-if="dialog.type === 'double' && typeRadioSelected === 'double'">
                        <upload-files 
                            ref="targetFileUpload"
                            title="上传译文" 
                            :size="userInfo.featureConfig.singleFileSize"
                            :accept="dialog.accept"
                            :multiple="true"
                            :limit="10"
                            :params="{}"></upload-files>
                    </div>
                </div>
                <div class="language-direction">
                    <div style="width:110px;">
                        <el-select v-model="dialog.source" placeholder="" size="small" @change="sourceSelectChange">
                            <template v-for="value in sourceLanguageList">
                                <el-option :key="value.key" :value="value.key" :label="value.name"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div><i class="el-icon-right"></i></div>
                    <div style="width:110px;">
                        <el-select v-model="dialog.target" placeholder="" size="small">
                            <template v-for="value in targetLanguageList">
                                <el-option :key="value.key" :value="value.key" :label="value.name" :disabled="value.key === dialog.source"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div>
            </div>
            <div slot="footer" style="text-align: center;">
                <el-button round plain size="small" @click="dialog.show = false">取消</el-button>
                <el-button round type="primary" size="small" :loading="dialog.loading" @click="startTaskEvent">术语提取</el-button>
            </div>
        </el-dialog>
         <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteTask"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";
import UpgradePro from '@/components/UpgradePro';
import FullLoading from '@/components/FullLoading';
import { toolsApi, commonApi } from '@/utils/api';
import config from '@/utils/config';
import AssetTabs from '../components/Tabs';
import CardList from '../components/CardList';
import UploadFiles from '@/components/UploadOss';
import AlertDialog from '@/components/AlertDialog';
import TypeRadio from '@/components/TypeRadio';
export default {
    components:{AssetTabs, CardList, UploadFiles, AlertDialog, FullLoading, UpgradePro, TypeRadio, },
    data(){
        return {
            refreshLoading: false,
            listTimer: null,
            alertInfo:{
                message:'确定要删除这个任务吗？',
                type:'confirm', //alert ,confirm
                buttonName:'确认删除',
                buttonType:'danger',
            },
            sourceLanguageList: [],
            targetLanguageList: [],
            typeRadioOptions:[{key: 'double', name: '双文档'}, {key: 'single', name: '单文档'}],
            typeRadioSelected:'double',
            dialog: {
                type: 'double', //single
                show: false,
                loading:false,
                uploadFileURL: toolsApi.uploadFile,
                source: 'zh',
                target: 'en',
                singleAccept: ".docx,.doc,.tmx", // .xlsx,.xls,.ppt,.pptx,.pdf
                singleSupportFiles: 'docx/doc/tmx', // /xlsx/xls/ppt/pptx/pdf
                accept: ".docx,.doc,.xlsx,.xls,.ppt,.pptx,.tmx", // ,.pdf
                supportFiles: 'docx/doc/xlsx/xls/ppt/pptx/tmx', // /pdf
            },
            searchForm: {
                kw: '',
                sort: '1'
            },
            pageSize:20,
            pageNumber:1,
            queryType: 'init',
            list: [],
            total: 0,
            searchTimer: null,
        }
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
		}),
	},
    created() {
        this.initLanguageList();  
    },
    methods:{
        async initLanguageList(){
            let url = `${toolsApi.queryGPTTranslateLanguageOptions}?engine=azure-gpt`;
            const res = await this.$ajax.get(url);
            if(res.status === 200){
                this.sourceLanguageList = res.data.sourceLanguages;
                this.targetLanguageList = res.data.targetLanguages;
            }
        },
        initPage(type){
            if(type === 'init'){
                this.$refs.fullLoading.setShow();
            }
            if(type === 'reload'){
                this.refreshLoading = true;
            }
            let url = `${toolsApi.queryETTasklist}?pageNo=${this.pageNumber}&pageSize=${this.pageSize}&keyword=${encodeURI(this.searchForm.kw)}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = res.data.pager.records;
                    this.total = res.data.pager.total;
                }
            }).finally(()=>{
                this.$refs.fullLoading.setHide();
                this.refreshLoading = false;
            })
        },
        sourceSelectChange(value){
            if(value === this.dialog.target){
                if(value==='zh'){
                    this.dialog.target = 'en';
                }else{
                    this.dialog.target = 'zh';
                }
            }
        },
        startTaskEvent(){
            let sourceFiles = this.$refs.fileUpload.getOssFiles();
            if(sourceFiles.length === 0){
                this.$message.error(this.dialog.type === 'double' && this.typeRadioSelected === 'double' ? '请上传原文' : '请上传文档');
                return;
            }
            let targetFiles = [];
            if(this.dialog.type === 'double' && this.typeRadioSelected === 'double') {
                targetFiles = this.$refs.targetFileUpload.getOssFiles();
                if(targetFiles.length === 0){
                    this.$message.error('请上传译文');
                    return;
                }
            }
            this.dialog.loading = true;
            let url = toolsApi.startETTask;
            const postData = {
                sourceLang:this.dialog.source,
                targetLang:this.dialog.target,
                toolType:'ET',
                bilingual: this.dialog.type === 'double',
                originalFiles: {
                    source: sourceFiles,
                    target:  targetFiles.length? targetFiles : undefined,
                },
                title:`${sourceFiles[0].fileName}`,
            };
            console.log(postData);
            // return;
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$message.success('任务已开始执行');
                    this.dialog.show = false;
                    this.initPage();
                }
            }).finally(()=>{
                this.dialog.loading = false;
            }) 
        },
        handleCurrentChange(num){
            this.pageNumber = num;
            this.initPage();
        },
        executeAction(key,params){
            switch (key){
                case 'excel':
                case 'tmx':
                case 'tbx':
                    this.downloadResult(key,params);
                    break;
                case 'delete':
                    this.$refs.alertDialogDom.setShow(true);
                    this.selectedTaskIds = [params.taskId];
                    break;
                case 'goto':
                    this.$router.push({path:'/tools/termExtraction/detail',query:{id:params.taskId}});
                    break;
            }
        },
        downloadResult(type,params){
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                title: params.title,
                metadataIdList: [params.taskId],
                metadataType: 'TOOLKIT_TE',
                moreOptions: {
                    exportFileType: type,
                }
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(() =>{
                
            });
        },
        deleteTask(){
            let url = toolsApi.deleteTask;
            this.$ajax.post(url,{
                ids:this.selectedTaskIds,
                toolType:'ET'
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success('任务已删除');
                    this.initPage();
                }
            })
        },
        inputSearchEvent(value){
            let self = this;
            if(value.length > 0){
                this.queryType = 'search';
            }else{
                this.queryType = 'init';
            }
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                self.initPage();
            },500);
        },
        typeRadioChange(item){
            this.typeRadioSelected = item.key;
        },
    },
    mounted(){
        let self = this;
        this.initPage('init');
        if(this.listTimer){
            clearInterval(this.listTimer);
        }
        this.listTimer = setInterval(()=>{
            self.initPage('reload');
        },5000);
    },
    destroyed(){
        clearInterval(this.listTimer);
    },
}
</script>
<style lang="scss" scoped>
.term-extraction-index-page{
    // padding: 20px;
}
.dialog-content{
    > .tips{
        text-align: center;
        font-size: 12px;
        margin-bottom: 20px;
    }
    > .language-direction{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px; 
        i{
            font-size: 20px;
            margin: 0 10px;
        }
    }
    .upload-files-content{
        display: flex;
        div {
            flex: 1;
        }
    }

}
</style>
